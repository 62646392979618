import { useState } from "react";
import ProductCard from "../components/common/ProductCard";
import { Button } from "react-bootstrap";
function Laptops() {

    const [laptopDetails, setLaptopDetails] = useState(
        [{
            image_url: '/assets/images/laptop.webp',
            product_desc: 'Pilates 14" Touch Screen Laptop 12GB Memory',
            price: '$85.00',
            rating: '3',
        },
        {
            image_url: '/assets/images/laptop-2.webp',
            product_desc: 'Pilates 14" Touch Screen Laptop 12GB Memory',
            price: '$85.00',
            rating: '3',
        },
        {
            image_url: '/assets/images/laptop.webp',
            product_desc: 'Pilates 14" Touch Screen Laptop 12GB Memory',
            price: '$85.00',
            rating: '3',
        },
        {
            image_url: '/assets/images/laptop-2.webp',
            product_desc: 'Pilates 14" Touch Screen Laptop 12GB Memory',
            price: '$85.00',
            rating: '3',
        },]
    )
    const data = {
        "image_url": "/assets/images/laptop-2.webp",
        "product_desc": "'Pilates 14' Touch Screen Laptop 12GB Memory",
        "price": "$85.00",
        "rating": 3
    }
    function addLaptops() {
        fetch("http://localhost:3000/laptop", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(json => console.log("Response json", json))
            .catch(error => console.error('Error:', error));
    }
    return (
        <>
            <ProductCard Props={laptopDetails} />
            <Button variant="primary" onClick={addLaptops}>Add Laptop</Button>
        </>
    )
}

export default Laptops;